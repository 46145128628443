// REACT
import React from "react"
// GATSBY
import { useStaticQuery, graphql } from "gatsby"
// MUI
import { Hidden, Grid, Typography, useMediaQuery } from "@material-ui/core"
import { useTheme } from "@material-ui/core/styles"
// COMPONENTS
import Layout from "../components/layout"
import BsmLogo from "../assets/bsm-logo.svg"
import EvinrudeBrpLogo from "../assets/logo-evinrude-brp.svg"
import { ImageSlider } from "../components/imageSlider"
import { ImageWithCaption } from "../components/imageWithCaption"
import { Link } from "../components/link"

// COMPONENT FUNCTION
const IndexPage = () => {
  // MEDIA QUERY
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down("sm"))

  // IMAGES
  const data = useStaticQuery(graphql`
    query {
      alcarBoatsLogo: file(relativePath: { eq: "alcar-boats-logo.jpg" }) {
        ...BsmFluidImage
      }
      alcarBoatsLogo: file(relativePath: { eq: "alcar-boats-logo.jpg" }) {
        ...BsmFluidImage
      }
      brpPlatinum: file(relativePath: { eq: "brp-platinum.jpg" }) {
        ...BsmFluidImage
      }
      tideCalendar: file(relativePath: { eq: "tide-calendar.png" }) {
        ...BsmFluidImage
      }
      hs01: file(relativePath: { eq: "hs01.jpg" }) {
        ...BsmFluidImage
      }
      hs02: file(relativePath: { eq: "hs02.jpg" }) {
        ...BsmFluidImage
      }
      hs03: file(relativePath: { eq: "hs03.jpg" }) {
        ...BsmFluidImage
      }
      hs04: file(relativePath: { eq: "hs04.jpg" }) {
        ...BsmFluidImage
      }
      hs05: file(relativePath: { eq: "hs05.jpg" }) {
        ...BsmFluidImage
      }
      hs06: file(relativePath: { eq: "hs06.jpg" }) {
        ...BsmFluidImage
      }
      hs07: file(relativePath: { eq: "hs07.jpg" }) {
        ...BsmFluidImage
      }
    }
  `)

  const imageSteps = [
    {
      label: "Bay Sails Marine storefront",
      imgPath: data.hs01.childImageSharp.fluid.src,
    },
    {
      label: "Hauling a boat",
      imgPath: data.hs02.childImageSharp.fluid.src,
    },
    {
      label: "Stepping a mast",
      imgPath: data.hs03.childImageSharp.fluid.src,
    },
    {
      label: "Pumpout boat",
      imgPath: data.hs04.childImageSharp.fluid.src,
    },
    {
      label: "Water test",
      imgPath: data.hs05.childImageSharp.fluid.src,
    },
    {
      label: "Lifting a boat",
      imgPath: data.hs06.childImageSharp.fluid.src,
    },
    {
      label: "Fabrication shop",
      imgPath: data.hs07.childImageSharp.fluid.src,
    },
  ]

  // COMPONENT
  return (
    <Layout title="Home">
      <Grid
        container
        spacing={3}
        alignItems="center"
        style={{ marginBottom: "4rem", paddingTop: "1rem" }}
      >
        <Grid item xs={12} sm={7}>
          <Typography
            variant={mobile ? "h4" : "h2"}
            component="h1"
            gutterBottom
          >
            The Premier Marine Services Provider on Lower Cape Cod
          </Typography>
          <Typography
            style={mobile ? { fontSize: "1rem" } : { fontSize: "1.4rem" }}
          >
            Established in 1970,{" "}
            <Link to="/directions">located in historic Wellfleet, MA</Link>, and
            open year-round. We offer{" "}
            <Link to="/evinrude-outboard-motors">Evinrude motors</Link>,{" "}
            <Link to="/sunfish">Sunfish and Laser sailboats</Link>, new, used
            and <Link to="/brokerage">brokerage boats</Link>,{" "}
            <Link to="/venture-trailers">trailers</Link>,{" "}
            <Link to="/rentals-alcar-powerboat">rentals</Link>,{" "}
            <Link to="/parts">parts</Link>, <Link to="/moorings">moorings</Link>
            , <Link to="/marine-supplies">marine supplies</Link>,{" "}
            <Link to="/boat-hauling">boat hauling</Link>,{" "}
            <Link to="/boat-storage">boat storage</Link> and more.
          </Typography>
        </Grid>
        <Hidden xsDown>
          <Grid item xs={12} sm={5}>
            <BsmLogo
              style={{
                display: "block",
                margin: "0rem auto",
                width: "76%",
                maxWidth: "300px",
                height: "auto",
              }}
            />
          </Grid>
        </Hidden>
      </Grid>

      <div style={{ marginBottom: "2rem" }}>
        <ImageSlider steps={imageSteps} maxWidth={800} />
      </div>

      <div style={{ marginBottom: "4rem" }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={8}>
            <Typography paragraph>
              Bay Sails Marine is also the exclusive dealer for{" "}
              <Link to="/alcar-boats">Alcar boats</Link>, manufactured by CB
              Boatworks. As a pioneer in the pumpout boat industry we developed
              the Alcar Environmental, the original pumpout boat. Alcar pumpout
              boats help keep the water clean along the Eastern seaboard and
              across the country. Now we’ve adapted our Alcar boats to create
              the ideal line of salt water recreational boats. So come in and
              visit us for Alcar boats and all your boating needs.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ImageWithCaption
              fluid={data.alcarBoatsLogo.childImageSharp.fluid}
              caption="Alcar Boats Logo"
              maxWidth={400}
            />
          </Grid>
        </Grid>
      </div>

      <div style={{ marginBottom: "4rem" }}>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={8}>
            <Typography paragraph>
              Bay Sails Marine is a platinum authorized Evinrude and Johnson
              Dealership. We carry the full line of Evinrude and Johnson
              outboard motors, available in both two-stroke and four-stroke
              models and a wide range of HP. We have the outboard that will keep
              you going on the water.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <EvinrudeBrpLogo
                  style={{
                    display: "block",
                    margin: "0rem auto",
                    width: "76%",
                    maxWidth: "300px",
                    height: "auto",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <ImageWithCaption
                  fluid={data.brpPlatinum.childImageSharp.fluid}
                  caption="BRP Platinum Certified Dealer"
                  maxWidth={300}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>

      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} sm={4}>
          <ImageWithCaption
            fluid={data.tideCalendar.childImageSharp.fluid}
            caption="Wellfleet Tide Calendar"
            maxWidth={300}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <Typography paragraph>
            Our {new Date().getFullYear()}{" "}
            <Link to="/tide-calendars">tide calendars</Link> are available in
            our store now. Stop by and pick one up. Be sure to check out our{" "}
            <Link to="/news">News</Link> page for the latest happenings here.
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default IndexPage
